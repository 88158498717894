<template>
	<Menu />

    <div class="mx-auto px-6 md:px-20 pt-6 pb-12 lg:py-20 bg-repeat bg-opacity-40" :style="{ backgroundImage: 'url(' + require('@/assets/home/green-fibers.png') + ')' }">
        <div class="sm:grid sm:grid-cols-2 lg:grid-cols-3 gap-4 space-y-8 sm:space-y-0 px-4 sm:px-0">
            <div v-for="item in videos" :key="item.id">
                <div class="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
                    <button type="button" class="relative block w-full bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-azul-light" @click="setVideo(item)">
                        <span class="sr-only">Ver video</span>
                        <img class="w-full object-cover h-56" :src="require('@/assets/videos/' + item.img + '.png')" alt="" />
                        <div class="absolute inset-0 w-full h-full flex items-center justify-center" aria-hidden="true">
                            <svg class="h-20 w-20 text-rojo" fill="currentColor" viewBox="0 0 84 84">
                                <circle opacity="0.8" cx="42" cy="42" r="42" fill="white" />
                                <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                            </svg>
                        </div>
                    </button>
                </div>
                <div class="text-center pt-3">
                    <p class="md:mt-1 text-gray-100 lg:text-lg font-medium leading-tight">
                        {{ item.title }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    
    <ModalVideoLoMejor :link="state.actual.link" v-if="state.showVideo" @close="state.showVideo=false" />
</template>

<script>
import Menu from '@/components/core/Menu'
import { DocumentDownloadIcon } from '@heroicons/vue/solid'
import ModalVideoLoMejor from '@/components/ModalVideoLoMejor'
import { reactive } from 'vue';

const videos = [
    { title: 'Si tienen Pepsi, tienen NFL', img: '12-pepsi', link: 'pwqDdaFwT7Q'},
    { title: '¿Estás listo para probar Snickers Almond?', img: '07-snickers', link: 'Jjo03LCRQdY'},
    { title: 'XBOX Series X: Power Your Dreams', img: '10-xbox', link: 'SHpzPevI01I'},
    
    { title: 'Amazon Alexa deseos cumplidos', img: '05-alexa', link: 'i5XkhDCsYgM'},
    { title: 'Starlight', img: '02-caja-estrella', link: 'Nh-Ot_sWwsE'},
    { title: 'NFL KIO Networks League', img: '11-kio', link: 'pQrwBvvyFNc'},

    { title: 'Corporativo WOLF: Let The Hunt Begin', img: '09-wolf', link: '9Xo2fiiJRZA'},
    { title: 'KIOmanji', img: '06-kiomanji', link: 'E-tX8TL6UKs'},
    { title: 'Mary Kay', img: '03-marykay', link: 'EX5CukxhDUY'},

    { title: 'Softtek: Te quiero cerca, pero no me toques', img: '08-softek', link: '-nzdHQ0znq'},
    { title: 'Netflix: Más allá de la Luna', img: '01-caja-luna', link: '8jrnUVaQAWE'},
    { title: '¡Qué padre ser tu hij@!', img: '04-liverpool', link: 'mPiU-teuHDI'}, 
];

export default {
	components: {
		Menu,
		DocumentDownloadIcon,
        ModalVideoLoMejor
	},
	setup() {
		const state = reactive({
            actual: null,
            showVideo: false
        });

        function setVideo(item) {
            state.actual = item;
            state.showVideo = true;
        }

        return {
            state,
            videos,
            setVideo
        }
	},
}
</script>